import axios from 'axios'

export default {
  data: () => ({
    loading: false,
    user_id: 0,
    user: {},
    accounts: [],
    selectedUser: null,
    selectedUSPSRep: null,
    activity: {},
    activityNote: null
  }),

  mounted() {
    const { id } = this.$route.params
    this.user_id = id
    this.fetchUser()
  },

  methods: {
    goToAccount(id) {
      this.$router.push({ name: 'accounts-show', params: { id: id } })
    },

    copyRepID() {
      navigator.clipboard.writeText(`https://app.grandshipper.com/#/auth/register/${this.user.rep_id}`)
      this.$root.snackbar.show({ message: `${this.user.rep_id} copied to clipboard`, color: 'success' })
    },

    async addClient() {
      try {
        let color = 'error'
        let msg = 'Please select an Account'
        if (!this.selectedUser) {
          this.$root.snackbar.show({ message: msg, color: color });
          return
        }
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.post(`${url}/api/usps-reps/${this.user_id}/add-account`,
          { user_id: this.selectedUser },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        if (data.message) {
          msg = data.message
        } else {
          msg = 'Account successfully added'
          color = 'success'
        }

        this.showAddClient = false

        this.$root.snackbar.show({ message: msg, color: color });
        await this.fetchUser()
      } catch (error) {
        console.error('error', error)
      }
    },

    async addUSPSRep() {
      try {
        let color = 'error'
        let msg = 'Please select a USPS Rep'
        if (!this.selectedUSPSRep) {
          this.$root.snackbar.show({ message: msg, color: color });
          return
        }
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.post(`${url}/api/user/${this.user_id}/add-usps-rep`,
          { rep_id: this.selectedUSPSRep },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        if (data.message) {
          msg = data.message
        } else {
          msg = 'USPS Rep successfully added'
          color = 'success'
        }

        this.showAddUSPS = false

        this.$root.snackbar.show({ message: msg, color: color });
        await this.fetchUser()
      } catch (error) {
        console.error('error', error)
      }
    },

    async showConfirmDeleteUSPSRep(repId) {
      this.showConfirmDeleteUSPSRepDialog = true
      this.deleteRepId = repId
    },

    async hideConfirmDeleteUSPSRep() {
      this.showConfirmDeleteUSPSRepDialog = false
      this.deleteRepId = null
    },

    async removeUSPSRep() {
      try {
        let color = 'error'
        let msg = 'Please select a USPS Rep'
        if (!this.deleteRepId) {
          this.$root.snackbar.show({ message: msg, color: color });
          return
        }
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.post(`${url}/api/user/${this.user_id}/del-usps-rep`,
          { rep_id: this.deleteRepId },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        if (data.message) {
          msg = data.message
        } else {
          msg = 'USPS Rep successfully removed'
          color = 'success'
        }

        this.showConfirmDeleteUSPSRepDialog = false

        this.$root.snackbar.show({ message: msg, color: color });
        await this.fetchUser()
      } catch (error) {
        console.error('error', error)
      }
    },

    async addActivity() {
      try {
        let color = 'error'
        let msg = 'Please enter a note'
        if (!this.activityNote) {
          this.$root.snackbar.show({ message: msg, color: color });
          return
        }
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.post(`${url}/api/user/${this.user_id}/add-activity`,
          { activity: this.activityNote },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        if (data.message) {
          msg = data.message
        } else {
          msg = 'Activity successfully added'
          color = 'success'
        }

        this.showAddActivity = false

        this.$root.snackbar.show({ message: msg, color: color });
        await this.fetchUser()
        await this.fetchActivities()
      } catch (error) {
        console.error('error', error)
      }
    },



    async showAddActivityModal() {
      this.showAddActivity = true
      this.activityNote = ''
    },

    async showEditActivityModal(activity) {
      this.showEditActivity = true
      this.activity = { ...activity }
    },



    async showConfirmDeleteActivity(activityId) {
      this.showConfirmDeleteActivityDialog = true
      this.deleteActivityId = activityId
    },

    async hideConfirmDeleteActivity() {
      this.showConfirmDeleteActivityDialog = false
      this.deleteActivityId = null
    },

    async removeActivity() {
      try {
        let color = 'error'
        let msg = 'Please select un activity'
        if (!this.deleteActivityId) {
          this.$root.snackbar.show({ message: msg, color: color });
          return
        }
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.post(`${url}/api/user/${this.user_id}/del-activity`,
          { activity_id: this.deleteActivityId },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        if (data.message) {
          color = 'error'
          msg = data.message
        } else {
          msg = 'Activity successfully removed'
          color = 'success'
        }

        this.showConfirmDeleteActivityDialog = false

        this.$root.snackbar.show({ message: msg, color: color });
        await this.fetchUser()
        await this.fetchActivities()
      } catch (error) {
        console.error('error', error)
      }
    },


    async editActivity() {
      try {
        let color = 'error'
        let msg = 'Please enter a note'
        if (!this.activity.id) {
          this.$root.snackbar.show({ message: msg, color: color });
          return
        }
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.post(`${url}/api/user/${this.user_id}/edit-activity/${this.activity.id}`,
          { activity: this.activity },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        if (data.message) {
          msg = data.message
          if (data.status === 'ok') color = 'success'
        } else {
          msg = 'Activity successfully edited'
          color = 'success'
        }

        this.showEditActivity = false

        this.$root.snackbar.show({ message: msg, color: color });
        await this.fetchUser()
        await this.fetchActivities()
      } catch (error) {
        console.error('error', error)
      }
    },

    async fetchUser() {
      try {
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data } = await axios.get(`${url}/api/pb-reps/${this.user_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        this.user = {
          id: data.id,
          name: data.name,
          email: data.email,
          title: data.title,
          district: data.district,
          badge: data.badge,
          email_verified_at: data.email_verified_at ? 1 : 0,
          created_at: data.created_at,
        }
      } catch (error) {
        console.error(error)
      }
    },

    async deactivate(return_url_name = 'accounts') {
      try {
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data: { status } } = await axios.delete(`${url}/api/user/${this.user_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        if (status === 'ok') {
          this.$root.snackbar.show({ message: 'User deactivated.', color: 'success' });
          this.$router.push({ name: return_url_name })
        }
      } catch (error) {
        console.error('error', error)
      }
    },

    async activateAccount() {
      try {
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data: { status, message } } = await axios.patch(`${url}/api/usps-reps/${this.user_id}`, {}, {
          headers: { Authorization: `Bearer ${token}` }
        })

        if (status === 'ok') {
          this.$root.snackbar.show({ message: 'User is now verified.', color: 'success' });
          await this.fetchUser()
        } else {
          this.$root.snackbar.show({ message: 'Unable to verify user.', color: 'error' });
        }
      } catch (error) {
        console.error('error', error)
      }
    },

  }
}
