<template>
  <v-row>
    <v-col sm="12">
      <v-card>
        <v-card-title class="align-start">
          USPS Rep Details
          <v-spacer />
          <v-btn color="warning" class="text--white mr-4" :to="{ name: 'usps-change-password', params: { id: user_id }}">
            Change Password
          </v-btn>
          <v-btn
            color="primary"
            class="text--white"
            :to="{ name: 'usps-edit', params: { id: user_id }}"
          >
            Edit
          </v-btn>
          <v-btn color="error" class="text--white ml-4" @click="deactivate('usps')">
            Deactivate
          </v-btn>
        </v-card-title>
        <v-card-text class="text-no-wrap text--primary body-1">
          <v-row
            v-for="(value, key) in user"
            :key="key"
            class="py-1"
            dense
          >
            <v-col v-if="key == 'email_verified_at'" sm="4">
              Verified Email
            </v-col>
            <v-col v-else sm="4">
              {{ key | titleCase('_') }}
            </v-col>
            <v-col v-if="['created_at', 'last_login',].includes(key)">
              {{ value | readableDate }}
            </v-col>
            <v-col v-else-if="['rep_id', ].includes(key)">
              <a color="primary" @click="copyRepID">{{ value }}</a>
            </v-col>
            <v-col v-else-if="['email_verified_at', ].includes(key)">
              <div v-if="value">
                <v-icon color="success">mdi-check</v-icon>
              </div>
              <div v-else>
                <v-icon color="error">mdi-close</v-icon>
                <v-btn small color="primary" @click="activateAccount">Verify Now</v-btn>
              </div>
            </v-col>
            <v-col v-else>
              {{ value || '' }}
            </v-col>
            <v-col sm="12">
              <v-divider />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col sm="12">
      <v-dialog
        v-model="showAddClient"
        persistent
        width="550"
      >
        <v-card>
          <v-card-title>Add Client</v-card-title>

          <v-container>
            <VAutocomplete
              v-model="selectedUser"
              :items="users"
              label="Select User"
              hide-details
              outlined
            ></VAutocomplete>
          </v-container>

          <v-card-actions class="justify-end">
            <v-btn color="warning" @click="showAddClient = false">Cancel</v-btn>
            <v-btn color="primary" @click="addClient">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card>
        <v-card-title>
          Accounts Attached To Rep
          <v-spacer />
          <v-btn color="primary" class="text--white mr-4" @click="showAddClient = true">
            Add Client
          </v-btn>
        </v-card-title>
        <v-container fluid  v-if="accounts.length">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Email
                  </th>
                  <th class="text-left">
                    Company
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(a, i) in accounts" :key="i">
                  <td>
                    <a color="primary" @click="goToAccount(a.id)">{{ a.name }}</a>
                  </td>
                  <td>{{ a.email }}</td>
                  <td>{{ a.company }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- <v-list dense>
            <v-list-item v-for="account in accounts" :key="account.id" link @click="goToAccount(account.id)">
              {{ account.name }} {{ account.email }} {{ account.company }}
            </v-list-item>
          </v-list> -->
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTable from '@/components/data-table/DataTable.vue'

import axios from 'axios'
import AccountMixin from '@/mixins/account-mixin'

export default {
  mixins: [ AccountMixin ],

  components: {
    DataTable,
  },

  data: () => ({
    pageTitle: 'USPS Reps',
    showAddClient: false,
    accounts: [],
    users: [],
  }),

  watch: {
    pageTitle(newTitle) {
      document.title = newTitle;
    }
  },

  methods: {
    async fetchUser () {
      try {
        const token = this.$store.state.token
        const url = process.env.VUE_APP_API_URL

        const { data: { accounts, rep } } = await axios.get( `${url}/api/usps-reps/${this.user_id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })

        this.user = {
          id: rep.id,
          name: rep.name,
          email: rep.email,
          title: rep.title,
          rep_id: rep.rep_id,
          district: rep.district,
          // badge: rep.badge,
          email_verified_at: rep.email_verified_at ? 1 : 0,
          created_at: rep.created_at,
          last_login: rep.last_login,
          phone_number: rep.phone || '',
        }

        let title = 'USPS Reps'
        if(this.user.name){
          title = ['USPS', this.user.name || '']
          title[1] = title[1].split(' ')[0]
          title = title.filter((name)=>name).join(' - ')
        }
        this.pageTitle = title;


        this.accounts = accounts

        const { data: {data: userAccounts} } = await axios.get( `${url}/api/user`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        this.users = userAccounts.map( u => {
          let name = u.name ?? ''
          if (u.company) name += ` | ${u.company}`
          return {
            value: u.id,
            text: name.trim()
          }
        })
      } catch (error) {
        console.error('error', error)
      }
    },
  },
}
</script>
